import React, { useState, useContext } from "react";
import { Box, Button, Text } from "grommet";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import urlpaths from "./urlpaths.json";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import { forgotPassword } from "components/Auth/auth";
import AuthLayout from "components/AuthLayout";

export default function ForgotPassword() {
  const dispatch = useContext(GlobalDispatchContext);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    getValues,
  } = useForm();

  async function onSubmit() {
    let { email } = getValues();
    try {
      let res = await forgotPassword(email);
      dispatch({
        type: "SET_USER",
        payload: {
          id: null,
          name: "",
          email: email,
        },
      });
    } catch (err) {
      dispatch({
        type: "SET_MESSAGE",
        payload: {
          centerLayerType: "message",
          error: true,
          message: err.message,
        },
      });
      return;
    }
    navigate(urlpaths.resetPassword);
  }

  return (
    <AuthLayout>
      <Box className="container">
        <h1 className="title">Forgot password?</h1>
        <Text className="subTitle">
          Enter your email below, you will receive an email with
          instructions on how to reset your password in a few minutes.
          You can also set a new password if you've never set one
          before.
        </Text>

        <form onSubmit={handleSubmit(onSubmit)} id="loginForm">
          <Box align="center" width="100">
            <Box width="large">
              <Box width="100%" align="center" gap="xsmall">
                <Box width="100%">
                  <Box className="field_row">
                    <label htmlFor="email-sign-up-simple">
                      Email
                    </label>
                    <div className="input_wrapper">
                      <input
                        id="email-sign-up-simple"
                        aria-invalid={errors.email ? "true" : "false"}
                        className={errors.email ? "error" : ""}
                        {...register("email", {
                          required: "This field is required.",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Must be valid email. example@yourdomain.com",
                          },
                        })}
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                      />
                      <svg
                        className="icon"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.344 5.344V4L8 7.344 2.656 4v1.344L8 8.656l5.344-3.312zm0-2.688c.354 0 .656.135.906.406.271.271.406.583.406.938v8c0 .354-.135.667-.406.938-.25.271-.552.406-.906.406H2.656c-.354 0-.667-.135-.937-.406A1.34 1.34 0 0 1 1.344 12V4a1.34 1.34 0 0 1 .375-.937c.271-.271.583-.406.937-.406h10.688z"></path>
                      </svg>
                    </div>
                    {errors.email && (
                      <div className="msg-error">
                        {errors.email.message}
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Button
                className="primary_button"
                label="Start recovery"
                size="large"
                primary
                type="submit"
              />
            </Box>
          </Box>
        </form>
        <Text
          weight="normal"
          className="link_text"
          margin={{ top: "large" }}
        >
          <button
            className="switch_page"
            onClick={() => navigate("/user/login")}
          >
            Sign in to your account
          </button>
        </Text>
      </Box>
    </AuthLayout>
  );
}
